
html, body {
  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*! `Custom` Bootstrap 5 theme */
@import url(https://use.fontawesome.com/releases/v5.0.10/css/all.css);

$primary:#8b40f1;
$secondary:#ae75fb;
$success:#28a745;
$danger:#dc3545;
$info:#17a2b8;
$warning:#ffc107;
$light:#f8f9fa;
$dark:#343434;
$enable-gradients:true;
$spacer:1rem;
$border-width:1px;
$btn-border-radius:.25rem;
$body-bg: white;
@import "bootstrap";

// Add SASS theme customizations here..