.carousal-item{

    min-width: 100% ;

    align-items: flex;
    justify-content: center;
    transition: all;
    border-style: groove;
    border-radius: 10px;
    border: 2px solid transparent;
    padding: 5px 5px 5px;
    vertical-align: top;
    display: inline-block;
    text-align: center;
    height: 20rem;
    width: 15rem ;

}
.carousal-item-img {
    height: 15rem;
    width: 15rem ;
    background-color: grey;
}

.carousal-item-name{

    display:block;
    font-style: inherit;

}
