#root {
  text-align: center;
  background-image: url(../public/images/landingBg.svg);
  background-size: cover;
  padding: 0px;
  height: 100vh; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;


}

html, body {
  height: 100vh; 
  display: flex;
  flex-direction: column;
}

h1 {
  color: white;
}

.App {
  background: url(../public/images/landingBg.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  overflow: auto;
}

