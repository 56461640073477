

.collaborator-img {
        max-height: 60px;
        max-width: 140px;
        min-width: 60px;
        margin: 10px;
}

.growathon-img {
        max-height: 60px;
        margin: 10px
}